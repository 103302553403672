// Since ace doesn't support base64 by default, this file contains rulesets needed to format it.

ace.define("ace/mode/base64_highlight_rules", ["require","exports","module","ace/lib/oop","ace/mode/text_highlight_rules"], function(require, exports, module) {
  "use strict";

  var oop = require("../lib/oop");
  var TextHighlightRules = require("./text_highlight_rules").TextHighlightRules;

  var base64HighlightRules = function() {
    this.$rules = {
      start: [
        {
          token: "constant.numeric", // base64
          regex: "(([A-Za-z0-9+/]{4})+([A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?|([A-Za-z0-9+/]{4})|([A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=))"
        },
        {
          token: "whitespace",
          regex: "\\s+"
        },
        {
          token: 'invalid.illegal',
          regex: '.+'
        }
      ]
    };
  };

  oop.inherits(base64HighlightRules, TextHighlightRules);
  exports.base64HighlightRules = base64HighlightRules;
});

ace.define('ace/mode/base64', function(require, exports, module) {

  var oop = require("ace/lib/oop");
  var TextMode = require("ace/mode/text").Mode;
  var base64HighlightRules = require("ace/mode/base64_highlight_rules").base64HighlightRules;
  
  var Mode = function() {
      this.HighlightRules = base64HighlightRules;
  };
  oop.inherits(Mode, TextMode);
  
  (function() {
      // Extra logic goes here. (see below)
  }).call(Mode.prototype);
  
  exports.Mode = Mode;
});

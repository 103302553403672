
const VALID_DATA = /^([a-fA-F0-9]{2}|\s)*$/i;
const REPLACE_DATA = /[^a-fA-F0-9]/ig;

// https://stackoverflow.com/a/40031979
const toBuffer = (hexString) => {
  if (!hexString) {
    return new Uint8Array();
  }
  return new Uint8Array(hexString.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));
}

// https://stackoverflow.com/a/53307879/643680
const fromBuffer = (buffer) => {
  var s = '', h = '0123456789ABCDEF';
  (new Uint8Array(buffer)).forEach((v) => { s += h[v >> 4] + h[v & 15] + " "; });
  return s;
}

const validate = (input) => {
  return VALID_DATA.test(input);
}

const sanitize = (input) => {
  return input.replace(REPLACE_DATA, '');
}

module.exports = {
  fromBuffer,
  toBuffer,
  validate,
  sanitize,
}


const VALID_DATA = /^\s*(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?\s*$/i;

// https://stackoverflow.com/a/36046727
const fromBuffer = function (u8) {
  return btoa(String.fromCharCode.apply(null, u8));
}

// https://stackoverflow.com/a/36046727
const toBuffer = function (str) {
  return atob(str).split('').map(function (c) { return c.charCodeAt(0); });
}

const validate = (input) => {
  return VALID_DATA.test(input);
}

const sanitize = (input) => {
  return input.trim();
}

module.exports = {
  fromBuffer,
  toBuffer,
  validate,
  sanitize,
}

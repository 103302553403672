import $ from "jquery";

import { loadServerHandler } from './ui/server';
import { loadMainHandler } from './ui/main';

$(document).ready(function() {

  loadServerHandler((provider) => {
    loadMainHandler(provider);
  });

});



import protobuf from 'protobufjs';
import { ProtobufModel } from './model';

/**
 * Loads and resolves proto definitions so they can be easily loaded and transformed.
 */
export function ProtobufProvider(baseURL) {
  // We need directory as base location
  if (!baseURL.endsWith('/')) {
    baseURL += '/';
  }

  this.baseURL = baseURL;

  const root = new protobuf.Root();
  const originalResolvePath = root.resolvePath;

  const loadedFiles = [];

  // We need to override the original mechanism used to resolve proto files as those won't work 
  // when used in browser as expected.

  /**
   * Resolves the specified include path against the specified origin path.
   * @param {string} originPath Path to the origin file
   * @param {string} includePath Include path relative to origin path
   * @param {boolean} [alreadyNormalized=false] `true` if both paths are already known to be normalized
   * @returns {string} Path to the include file
   */
  const resolvePath = function(originPath, includePath, alreadyNormalized) {

    // Do not prefix base classes
    if (includePath.indexOf('google/protobuf') === 0) {
      return originalResolvePath(originPath, includePath, alreadyNormalized);
    }
    
    // Resolve relative paths normally
    if (includePath.startsWith('.')) {
      return originalResolvePath(originPath, includePath, alreadyNormalized);
    }

    // This works-around for "absolute" paths that do not start with /
    return originalResolvePath('', baseURL + includePath, true);
  };

  // Since protobuf doesn't allow overriding of the same class name in same namespace, this method
  // ensures that we load each proto file exactly once.
  let cacheResolvePath = function(originPath, includePath, alreadyNormalized) {
    let path = resolvePath(originPath, includePath, alreadyNormalized);

    if (loadedFiles.includes(path)) {
      return null;
    }

    loadedFiles.push(path);
    return path;
  }

  this.root = root;
  this.root.resolvePath = cacheResolvePath;
}

/**
 * Loads the provided list of proto definitions.
 */
ProtobufProvider.prototype.load = function(files) {
  return this.root.load(files);
}

/**
 * Returns all the model FQDN that were loaded.
 */
ProtobufProvider.prototype.getTypes = function() {
  function getList(node) {
    const nodeType = node.constructor.name;

    if (nodeType == "Type") {
      return {
        name: node.name,
      };
    }

    // Recursive call on those that can have children
    if (nodeType === "Namespace" || nodeType === "Root") {
      const prefix = node.name;
  
      // Recursive call on the children
      const children = node.nestedArray.map(getList)
  
      // Join the children
      let list = [];
      children.forEach(element => {
        if (element) {
          list = list.concat(element);
        }
      });
  
      if (nodeType === "Namespace") {
        list = list.map(element => {
          return {
            name: prefix + '.' + element.name,
          };
        })
      }
  
      return list;
    }
  
    return null;
  }

  return getList(this.root);
}

/**
 * Returns the ProtobufModel for given model FQDN or null if not found.
 */
ProtobufProvider.prototype.getModel = function(modelName) {
  try {
    const protoModel = this.root.lookupType(modelName)
    return new ProtobufModel(protoModel);
  }
  catch(err) {
    return null;
  }
}

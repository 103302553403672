// Since ace doesn't support hex by default, this file contains rulesets needed to format it.

ace.define("ace/mode/hex_highlight_rules", ["require","exports","module","ace/lib/oop","ace/mode/text_highlight_rules"], function(require, exports, module) {
  "use strict";

  var oop = require("../lib/oop");
  var TextHighlightRules = require("./text_highlight_rules").TextHighlightRules;

  var HexHighlightRules = function() {
    this.$rules = {
      start: [
        {
          token: "constant.numeric", // hex
          regex: "[0-9a-fA-F]{2}"
        },
        {
          token: "whitespace",
          regex: "\\s+"
        },
        {
          token: 'invalid.illegal',
          regex: '.+'
        }
      ]
    };
  };

  oop.inherits(HexHighlightRules, TextHighlightRules);
  exports.HexHighlightRules = HexHighlightRules;
});

ace.define('ace/mode/hex', function(require, exports, module) {

  var oop = require("ace/lib/oop");
  var TextMode = require("ace/mode/text").Mode;
  var HexHighlightRules = require("ace/mode/hex_highlight_rules").HexHighlightRules;
  
  var Mode = function() {
      this.HighlightRules = HexHighlightRules;
  };
  oop.inherits(Mode, TextMode);
  
  (function() {
      // Extra logic goes here. (see below)
  }).call(Mode.prototype);
  
  exports.Mode = Mode;
});

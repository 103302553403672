
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
let $ = jQuery;

const fuzzysort = require('fuzzysort');

require('easy-autocomplete');

const converters = {
  hex: require('../protobuf/converters/hex'),
  base64: require('../protobuf/converters/base64'),
}

/**
 * Handler for loading the classes screen.
 */
export function loadClassHandler(provider, cb) {
  const screen = $("#load-class");
  const form = $("form", screen);

  // Context for computed fuzzy scores
  const scores = {};

  // Enable auto-complete.
  $("input[name='proto-class']", screen).easyAutocomplete({
    list: {
      maxNumberOfElements: 10,
      sort: {
        enabled: true,
        method: (a, b) => {
          return scores[b.name.toLowerCase()] == scores[a.name.toLowerCase()];
        }
      },
      match: {
        enabled: true,
        method: (elementValue, phrase) => {
          const r = fuzzysort.single(phrase, elementValue);
          if (r == null) return false;

          // Store score needed for sort method
          scores[elementValue.toLowerCase()] = r.score;
          // Discard those very far off
          return r.score > -2000;
        },
      }
    },
    theme: "square",
    data: provider.getTypes(),
    getValue: element => element.name,
    adjustWidth: false,
  });

  // Show the panel and focus on the input field
  screen.fadeIn();
  $("input[name='proto-class']", screen).focus();

  // Add handler for submiting the form
  form.on("submit", (e) => {
    e.preventDefault();

    // Get form values
    const protoClass = $("input[name='proto-class']", screen).val();
    const format = $("input[name='format']:checked").val();

    if (!protoClass) {
      return alert("Protobuf class name cannot be empty!");
    }

    // Instantiate new model used
    const model = provider.getModel(protoClass);
    if (!model) {
      return alert("Invaild model name!");
    }

    // Configure converter and add extra data
    model.setConverter(converters[format]);
    model.extra = {
      name: protoClass,
      format: format,
    };

    // Fade out the screen
    screen.fadeOut(() => {
      // Reset the input field once the screen fades out
      $("input[name='proto-class']", screen).val("");
    });

    form.off('submit');
    cb(model);
  })
}


/**
 * Model used to convert the proto definition from object into bytes and reverse.
 * The converter is used for data serialization (eg. hex / base64).
 */
export function ProtobufModel(model, converter) {
  this.model = model;
  this.converter = converter;
};

/**
 * Sets the converter used to decode the input and encode the output from raw bytes.
 */
ProtobufModel.prototype.setConverter = function(converter) {
  this.converter = converter;
}

/**
 * Returns the object with default values.
 */
ProtobufModel.prototype.getDefault = function() {
  return this.model.toObject({}, {
    enums: String,  // enums as string names
    longs: String,  // longs as strings (requires long.js)
    bytes: String,  // bytes as base64 encoded strings
    defaults: true, // includes default values
    arrays: true,   // populates empty arrays (repeated fields) even if defaults=false
    objects: true,  // populates empty objects (map fields) even if defaults=false
    oneofs: true    // includes virtual oneof fields set to the present field's name
  });
}

/**
 * Converts object into encoded binary.
 */
ProtobufModel.prototype.encode = function(object) {
    const message = this.model.fromObject(object);
    
    let err = null;

    err = this.model.verify(message);
    if (err) {
      alert("Validation failed! Message will still be attempted to be serialized.\nError: " + err);
    }

    const buffer = this.model.encode(message).finish();

    // Call the binary encoder
    return this.converter.fromBuffer(buffer);
};

/**
 * Converts the provided input (encoded binary) into object.
 */
ProtobufModel.prototype.decode = function(input) {
  if (!this.converter.validate(input)) {
    throw new Error("Input is not valid!");
  }

  // Validate the binary data is properly formated
  const sanitized = this.converter.sanitize(input);
  // Decode the binary content
  return this.model.decode(this.converter.toBuffer(sanitized));
};

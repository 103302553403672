import $ from "jquery";

import Split from "split.js";

import { Panel } from './panel';
import { loadClassHandler } from './class';

export function loadMainHandler(provider) {
  const mainScreen = $("#main");
  $("#base-url").html(provider.baseURL);

  mainScreen.fadeIn();

  const instances = [];
  let splitInstance;
  let index = 0;

  // Ensures that all editors get resized to avoid scroll issues with ACE editor
  const updateEditorCount = () => {
    // Get all panels except the template
    const panels = $.map($(".panel"), (el, index) => "#" + el.id).filter(el => el !== "#panel-{{id}}");

    // Delete existing split handler if exists
    if (splitInstance) splitInstance.destroy(true);
    if (panels.length == 0) {
      // Do not create new when there are no panels
      splitInstance = undefined;
      return;
    }

    splitInstance = Split(panels, {
       direction: "vertical",
       minSize: 100,
       expandToMin: true,
       gutterSize: 3,
       onDragEnd: () => {
         // After each resize, force an editor resize
         instances.forEach(editor => editor.resize())
       }
     });

     // Force a editor resize since something changed
     instances.forEach(editor => editor.resize())
  }

  // Handler for adding a new class
  const loadNewClass = () => {
    loadClassHandler(provider, (model) => {
      const ids = index++;

      const panel = new Panel(ids, model, () => {
        // Resize the panels when editor gets deleted;
        setTimeout(updateEditorCount, 50);
      });

      // Store the editors to allow for a refresh when needed
      instances.push(panel.jsonEditor);
      instances.push(panel.protoEditor);

      // Force a editor refresh
      setTimeout(updateEditorCount, 50);
    })
  }

  // Load class when the main screen shows
  loadNewClass();

  // Add handler for loading new classes
  $('#add-panel', mainScreen).on('click', () => {
    loadNewClass();
  })
}

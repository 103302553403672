
import { copyTextToClipboard } from '../utils/clipboard';

// Load the ACE editor
import 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/mode-json';
import "../ace/mode-base64";
import "../ace/mode-hex";

// Load the worker needed for JSON validation, this doesn't work with packager:
// https://github.com/ajaxorg/ace-builds/issues/129
const CDN = 'https://cdn.jsdelivr.net/npm/ace-builds@1.4.8/src-min-noconflict';
ace.config.set('workerPath', CDN);

/**
 * DOM Panel used to convert the proto definitions.
 */
export function Panel(id, model, closeHandler) {
  const { name, format } = model.extra;

  this.id = id;
  this.encodingFormat = format;
  this.model = model;
  this.closeHandler = closeHandler;

  // Render the template and add it to DOM
  let template = $('#template-section').html();
  template = template.replace(/{{id}}/g, id);
  template = template.replace(/{{class}}/g, name);
  $('#panels').append(template);

  // Store the reference to container element
  const el = $('#panel-' + id);
  this.el = el;

  // Initialize editors
  this.jsonEditor = this.initializeEditorJSON();
  this.protoEditor = this.initializeEditorProtobuf();
  // Fill in the JSON editor with default value
  const newData = JSON.stringify(model.getDefault(), null, 4);
  this.jsonEditor.setValue(newData);
  this.jsonEditor.clearSelection();

  // Add all the handlers
  this.initializeHandlers();
}

/**
 * Configures the handlers for all the buttons and actions.
 */
Panel.prototype.initializeHandlers = function() {
  const [btnProtoCopy, btnProto, btnJSON, btnJSONCopy, btnClose] = $('button', this.el);
  const tooltipElement = $('.tooltip', this.el);

  // Add handler for submiting the JSON editor
  this.jsonEditor.commands.addCommand({
    name: "convert-json-to-protobuf",
    bindKey: { win: "Ctrl-Enter", mac: "Command-Enter" },
    exec: () => this.toProto(),
  });

  // Add handler for submiting the Protobuf editor
  this.protoEditor.commands.addCommand({
    name: "convert-protobuf-to-json",
    bindKey: { win: "Ctrl-Enter", mac: "Command-Enter" },
    exec: () => this.toJSON(),
  });

  // Add handler for JSON -> Proto button
  $(btnProto).on('click', () => this.toProto());

  // Add handler for JSON -> Proto and copy button
  $(btnProtoCopy).on('click', () => this.toProto((newData) => {
    copyTextToClipboard(newData);
    tooltipElement.fadeIn(100).delay(2000).fadeOut(100);
  }));

  // Add handler for Proto -> JSON button
  $(btnJSON).on('click', () => this.toJSON());

  // If there is paste support add handler for past and convert Proto -> JSON
  // https://developers.google.com/web/updates/2018/03/clipboardapi
  if (!navigator.clipboard) {
    $(btnJSONCopy).hide();
  } else {
    $(btnJSONCopy).on('click', () => {
      navigator.clipboard.readText().then((data) => {
        this.protoEditor.setValue(data);
        this.protoEditor.clearSelection();
        this.toJSON();
      })  
    });
  }

  // Add handler for closing the panel
  $(btnClose).on('click', () => {
    // Unload the input editors
    this.jsonEditor.destroy();
    this.protoEditor.destroy();
    // Destroy the panel and reload the resizer
    this.el.remove();
    this.closeHandler();
  });
}

/**
 * Initializes the JSON editor.
 */
Panel.prototype.initializeEditorJSON = function() {
  const editor = ace.edit(`json-editor-${this.id}`);
  editor.session.setMode('ace/mode/json');
  editor.setFontSize(13);
  return editor;
}

/**
 * Initializes the Protobuf editor with appropriate syntax highlighting.
 */
Panel.prototype.initializeEditorProtobuf = function() {
  const editor = ace.edit(`proto-editor-${this.id}`);
  editor.session.setMode(`ace/mode/${this.encodingFormat}`);
  editor.setOptions({
    wrap: true,
    fontSize: 13,
    indentedSoftWrap: false,
  })

  return editor;
}


/**
 * Handler for conversion form JSON to protobuf.
 */
Panel.prototype.toProto = function(clipboardHandler) {
  const input = this.jsonEditor.getValue() || '';
  let value;

  try {
    let object = JSON.parse(input);
    value = this.model.encode(object);
  } catch (err) {
    console.log(err);
    return alert(err);
  }

  // Execute the clipboard handler for new value
  if (clipboardHandler) {
    clipboardHandler(value);
  }

  // Update the editor
  this.protoEditor.setValue(value);
  this.protoEditor.clearSelection();
};


/**
 * Handler for conversion form protobuf to JSON.
 */
Panel.prototype.toJSON = function(clipboardHandler) {
  const input = this.protoEditor.getValue() || '';
  let value;

  try {
    let message = this.model.decode(input);
    value = JSON.stringify(message, null, 4);
  } catch (err) {
    console.log(err);
    return alert(err);
  }

  // Execute the clipboard handler for new value
  if (clipboardHandler) {
    clipboardHandler(value);
  }

  // Update the editor
  this.jsonEditor.setValue(value);
  this.jsonEditor.clearSelection();
};



import $ from "jquery";

import { ProtobufProvider } from '../protobuf/provider';

/**
 * Handler for server loading screen.
 */
export function loadServerHandler(cb) {
  const screen = $("#load-server");

  // Focus and prefill the input
  const serverInput = $("input[name='base-path']", screen);
  serverInput.focus();
  serverInput.val("http://localhost:8000/");

  $("form", screen).on('submit', (e) => {
    e.preventDefault();

    // Get the input URL
    const baseURL = $("input[name='base-path']", screen).val();

    if (!baseURL) {
      return alert("Base URL is required!");
    }

    // Load the list of proto files
    $.get(baseURL + (!baseURL.endsWith('/') ? '/' : '') + 'protos.json').promise().then((data) => {
      let provider = new ProtobufProvider(baseURL);

      // Load the definitions
      provider.load(data).then((status) => {
        screen.fadeOut();
        cb(provider);
      }).catch((err) => {
        console.error(err);
        return alert("Unable to load definitions. Ensure that the proto definitions are served from the correct base directory!");
      });
    }).catch((err) => {
      console.error(err);
      return alert ("Unable to load list of proto definitions from given URL.\nRefer to the developer console for more details.")
    });
  });
};

